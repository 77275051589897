import * as actions from 'redux/actions/types';

const initialState = {
  user: {
    _id: '',
    Nome: '',
    Usuario: '',
    Tipo: '',
  },
  token: null,
  errors: {},
  loading: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.AUTH_START:
      return { ...state, loading: true }
    case actions.AUTH_SUCCESS:
      return { ...state, ...payload, loading: false }
    case actions.AUTH_FAIL:
      return { ...state, errors: payload, loading: false }
    case actions.AUTH_RESTORE:
      return { ...state, ...payload, loading: false }
    case actions.AUTH_SIGN_OUT:
      return { ...state, loading: false }
    default:
      return state;
  }
}
